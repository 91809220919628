import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import norkartIdJs from 'util/authService';
import Login from 'scenes/open/Login/Login';

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false
        };
        this.logIn = this.logIn.bind(this);
    }

    componentDidMount() {
        this.logIn();
    }
    async logIn() {
        let isLoggedIn = await norkartIdJs.isAuthenticated();
        if (isLoggedIn === true) {
            this.setState({isAuthenticated: isLoggedIn});
        } else {
            await norkartIdJs.logIn();
            isLoggedIn = await norkartIdJs.isAuthenticated();
            if (isLoggedIn === true) {
                this.setState({isAuthenticated: isLoggedIn});
            }
        }
    }
    render() {
        const {component: Component, ...rest} = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    this.state.isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Login isLoading={!this.state.isAuthenticated} />
                    )
                }
            />
        );
    }
}

/*const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            return authService.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {nextLocation: props.location.pathname}
                    }}
                />
            );
        }}
    />
);*/

export default PrivateRoute;
