import React from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {Tooltip} from '@material-ui/core';

const theme = createMuiTheme({
    overrides: {
        // Name of the component ⚛️ / style sheet
        MuiTooltip: {
            // Name of the rule
            tooltip: {
                fontSize: 13,
                borderRadius: 0
            }
        }
    }
});

function NorkartTooltip(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <Tooltip title={props.title} placement={props.placement}>
                {props.children}
            </Tooltip>
        </MuiThemeProvider>
    );
}
export default NorkartTooltip;
