import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import RoutePlanner from './RoutePlanner';
import './routePlanner.css';
import NkaIconButton from 'nka-icon-button';
import 'nka-icon-button/dist/style.css';
import 'nka-button/dist/style.css';
import {getDistanceString, getTimeString} from 'util/timeHandler';
import {fetchRoute, createRequestObject} from 'util/apiService';
import Via_icon from './icons/Via-icon.svg';
import Til_icon from './icons/Til-icon.svg';
import Fra_icon from './icons/Fra-icon.svg';
import proj4 from 'proj4';
import linjal_icon from './icons/nk_maalestokk-A.svg';
import 'core-js/es6/number';
class RoutePlannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredSearchLabels: ['Fra', 'Til'],
            subRoutes: [],
            subRouteId: 0,
            route: null,
            targets: ['matrikkelenhet', 'gate', 'gateadresse'],
            fromMarkerToolTip: null,
            toMarkerToolTip: null,
            viMarkerToolTip: null,
            placeholder: 'Søk: Adresse, matrikkel, koordinater',
            routeErrorMessage: null
        };
        this.createLabelSearchProps = this.createLabelSearchProps.bind(this);
        this.handleRemoveSubRoute = this.handleRemoveSubRoute.bind(this);
        this.removeLayer = this.removeLayer.bind(this);
        this.setSubRouteState = this.setSubRouteState.bind(this);
        this.createRoutePlannerProps = this.createRoutePlannerProps.bind(this);
        this.getResultFormatted = this.getResultFormatted.bind(this);
        this.getCoordinateResult = this.getCoordinateResult.bind(this);
        this.switchLayers = this.switchLayers.bind(this);
        this.reset = this.reset.bind(this);
    }
    componentDidMount() {
        if (sessionStorage) {
            let viaKeys = [];
            let highestIndex = 0;
            for (let i = 0; i < sessionStorage.length; i++) {
                let key = sessionStorage.key(i);
                if (
                    JSON.stringify(key)
                        .toLowerCase()
                        .includes('via')
                ) {
                    let intKey = key.match(/\d+/)[0];
                    let int = Number(intKey);
                    if (!intKey) {
                        return;
                    }
                    if (int > highestIndex) {
                        highestIndex = int;
                    }
                    viaKeys.push({text: 'Via', key: int});
                }
            }
            this.setState({
                subRoutes: viaKeys,
                subRouteId: highestIndex + 1
            });
        }
    }
    mustGetRoute(prevLayers, newLayers) {
        let bool = false;
        if (
            newLayers.some(layer => layer.id === 'Fra0') &&
            newLayers.some(layer => layer.id === 'Til1')
        ) {
            const newLayersWithoutRoute = newLayers.filter(layer => layer.id !== 'route');
            const prevLayersWithoutRoute = prevLayers.filter(layer => layer.id !== 'route');
            if (newLayersWithoutRoute.length !== prevLayersWithoutRoute.length) {
                bool = true;
            } else {
                newLayersWithoutRoute.forEach(newLayer => {
                    const oldLayer = prevLayersWithoutRoute.filter(
                        prevLayer => prevLayer.id === newLayer.id
                    );
                    if (!this.compareGeoJson(oldLayer[0], newLayer)) {
                        bool = true;
                    }
                });
            }
        }
        return bool;
    }

    compareGeoJson(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.layers !== prevProps.layers) {
            //filters out route and check if there are any changes to the points
            let bool = this.mustGetRoute(prevProps.layers, this.props.layers);
            if (bool) {
                this.updateRoute(this.props.layers);
            }
        }
        if (
            this.props.externalSubRoute &&
            this.props.externalSubRoute.lat &&
            this.props.externalSubRoute.lng &&
            (prevProps.externalSubRoute === null ||
                (this.props.externalSubRoute.lat !== prevProps.externalSubRoute.lat &&
                    this.props.externalSubRoute.lng !== prevProps.externalSubRoute.lng))
        ) {
            if (this.props.externalSubRoute) {
                this.setSubRouteState({
                    text: 'Via',
                    key: this.state.subRouteId
                });
                this.props.setMarker(
                    this.defineMarker(
                        this.props.externalSubRoute.lat,
                        this.props.externalSubRoute.lng,
                        {
                            name: 'Via',
                            index: this.state.subRouteId
                        }
                    )
                );
            }
        }

        if (this.state.route !== prevState.route) {
            this.props.setRouteResults(this.state.route);
        }
    }

    // async updateRoute(layers, zoomLevel = 1) {
    //     let simpleRoute = zoomLevel === 1 ? true : false;
    //     if (simpleRoute) {
    //         this.props.setLoading();
    //     }

    //     fetchRoute(createRequestObject(layers, zoomLevel))
    //         .then(result =>
    //             this.setState(
    //                 {
    //                     route: result.data,
    //                     routeErrorMessage: null
    //                 },
    //                 () => {
    //                     simpleRoute ? this.updateRoute(this.props.layers, 20) :
    //                         this.props.setLoading();
    //                 }
    //             )
    //         )
    //         .catch(error => {
    //             console.log(error);
    //             this.setState({
    //                 route: null,
    //                 routeErrorMessage: 'Ingen rute'
    //             });
    //             this.props.setLoading();
    //         });
    // }

    async updateRoute(layers) {
        this.props.setLoading();

        fetchRoute(createRequestObject(layers))
            .then(result =>
                this.setState(
                    {
                        route: result.data,
                        routeErrorMessage: null
                    },
                    () => {
                        this.props.setLoading();
                    }
                )
            )
            .catch(error => {
                console.log(error);
                this.setState({
                    route: null,
                    routeErrorMessage: 'Ingen rute'
                });
                this.props.setLoading();
            });
    }

    getResultFormatted(res, searchBoxInfo) {
        let coords = [];
        if (res.Source) {
            if (res.Source) {
                if (res.Source.Posisjon) {
                    coords.push(res.Source.Posisjon.X);
                    coords.push(res.Source.Posisjon.Y);
                } else if (res.Source.Adresser) {
                    if (res.Source.Adresser.length > 0) {
                        let pos = res.Source.Adresser[0].Posisjon;
                        coords.push(pos.X);
                        coords.push(pos.Y);
                    }
                }
            } else if (res.Source.Geometri) {
                coords = JSON.parse(res.Source.Geometri).coordinates;
            }
        } else if (res.Posisjon) {
            if (res.Posisjon) {
                coords.push(res.Posisjon.X);
                coords.push(res.Posisjon.Y);
            }
        }

        if (searchBoxInfo) {
            sessionStorage.setItem(
                searchBoxInfo.name + searchBoxInfo.index,
                JSON.stringify({
                    id: 1,
                    Text: res.Text,
                    Type: res.Type,
                    searchBoxKey: searchBoxInfo.index,
                    searchBoxName: searchBoxInfo.name
                })
            );
        }
        if (coords[0] && coords[1]) {
            let point = this.defineMarker(coords[1], coords[0], searchBoxInfo);
            let utmNumber = '+proj=utm +zone=33';
            let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

            let numberArray = [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])];
            let latlon = proj4(utmNumber, wgs84).inverse(numberArray);
            latlon[0] = latlon[0].toFixed(3) + 'E';
            latlon[1] = latlon[1].toFixed(3) + 'N';

            if (point) {
                this.setMarkerToolTipState(searchBoxInfo, latlon);
            }
            this.props.setAllLayers([point]);
            if (this.props.marker !== null) {
                this.props.setDraggedLayers(true, true);
            } else {
                this.props.setDraggedLayers(false);
            }
        }

        return res;
    }

    getCoordinateResult(res, searchBoxInfo) {
        let coords = [];
        if (res && res.geometry) {
            let regex = /UTM[0-9][0-9]:/;
            let regex2 = /utm[0-9][0-9]:/;

            let utm = res.geometry.coordinates.match(regex);
            if (!utm) {
                utm = res.geometry.coordinates.match(regex2);
            }
            if (utm) {
                if (utm[0]) {
                    utm = utm[0].match(/[0-9][0-9]/)[0];
                }
            }

            let coord = res.geometry.coordinates.substring(
                res.geometry.coordinates.indexOf(':') + 1,
                res.geometry.coordinates.length
            );
            coords = coord.trim().split(' ');

            coords[0] = coords[0].replace(',', '.');
            coords[1] = coords[1].replace(',', '.');
            let east =
                coords[0].toLowerCase().indexOf('e') > -1
                    ? coords[0]
                    : coords[1].toLowerCase().indexOf('e') > -1
                        ? coords[1]
                        : coords[0];
            let eastCheck = east.split('.')[0];
            let north =
                coords[1].toLowerCase().indexOf('n') > -1
                    ? coords[1]
                    : coords[0].toLowerCase().indexOf('n') > -1
                        ? coords[0]
                        : coords[1];
            let northCheck = north.split('.')[0];
            utm = utm ? utm : '33';
            let utmNumber = '+proj=utm +zone=' + utm;
            let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
            if (coords[0] && coords[1] && eastCheck.length > 3 && northCheck.length > 3) {
                let numberArray = [Number.parseFloat(east), Number.parseFloat(north)];
                let latlon = proj4(utmNumber, wgs84, numberArray);

                coords = latlon.reverse();
                // east = coords[0];
                // north = coords[1];
            } else {
                let utm33 = proj4(utmNumber, [
                    Number.parseFloat(coords[1]),
                    Number.parseFloat(coords[0])
                ]);
                east = utm33[0] + 'E';
                north = utm33[1] + 'N';
            }
            if (east && north) {
                if (searchBoxInfo) {
                    sessionStorage.setItem(
                        searchBoxInfo.name + searchBoxInfo.index,
                        JSON.stringify({
                            id: 1,
                            Text: res.geometry.coordinates,
                            Type: 'coordinate',
                            searchBoxKey: searchBoxInfo.index,
                            searchBoxName: searchBoxInfo.name
                        })
                    );
                }
                let point = this.defineMarker(
                    Number.parseFloat(coords[0]),
                    Number.parseFloat(coords[1]),
                    searchBoxInfo
                );

                if (point) {
                    this.setMarkerToolTipState(searchBoxInfo, [east, north]);
                }
                this.props.setAllLayers([point]);
                if (this.props.marker !== null) {
                    this.props.setDraggedLayers(true, true);
                } else {
                    this.props.setDraggedLayers(false);
                }
            }
        }

        return res;
    }
    setMarkerToolTipState(searchBoxInfo, coords) {
        if ('Fra0' === searchBoxInfo.name + searchBoxInfo.index) {
            this.setState({
                fromMarkerToolTip: {
                    id: searchBoxInfo.name + searchBoxInfo.index,
                    text: 'Fra: ' + this.defineMarkerToolTip(coords),
                    coordinates: {lat: coords[0], lng: coords[1]}
                }
            });
        }
        if ('Til1' === searchBoxInfo.name + searchBoxInfo.index) {
            this.setState({
                toMarkerToolTip: {
                    id: searchBoxInfo.name + searchBoxInfo.index,
                    text: 'Til: ' + this.defineMarkerToolTip(coords),
                    coordinates: {lat: coords[0], lng: coords[1]}
                }
            });
        }
        if ('Via' === searchBoxInfo.name) {
            let toolTips = this.state.subMarkerToolTip
                ? JSON.parse(JSON.stringify(this.state.subMarkerToolTip))
                : null;
            if (toolTips) {
                toolTips.push({
                    id: searchBoxInfo.name + searchBoxInfo.index,
                    text: 'Via: ' + this.defineMarkerToolTip(coords),
                    coordinates: {lat: coords[0], lng: coords[1]}
                });
                this.setState({subMarkerToolTip: toolTips});
            } else {
                this.setState({
                    subMarkerToolTip: [
                        {
                            id: searchBoxInfo.name + searchBoxInfo.index,
                            text: 'Via: ' + this.defineMarkerToolTip(coords),
                            coordinates: {lat: coords[0], lng: coords[1]}
                        }
                    ]
                });
            }
        }
    }
    defineMarkerToolTip(coords) {
        if (coords && coords.length > 1) {
            return ` ${coords[1]}, ${coords[0]}`;
        }
        return 'Fant ingen koordinater';
    }
    defineMarker = (lat, lng, searchBoxInfo) => {
        return {
            id: searchBoxInfo.name + searchBoxInfo.index,
            type: 'symbol',
            source: {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat]
                    },
                    properties: {
                        title: searchBoxInfo.name + searchBoxInfo.index,
                        icon: searchBoxInfo.name
                    }
                }
            },
            layout: {
                'icon-image': '{icon}-A',
                'icon-size': 0.23,
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true
            },
            properties: {
                draggable: true,
                popupContent: lat,
                lng,
                toolTip: 'UTM33',
                name: searchBoxInfo.name + searchBoxInfo.index,
                iconUrl:
                    searchBoxInfo.name === 'Til'
                        ? Til_icon
                        : searchBoxInfo.name === 'Fra'
                            ? Fra_icon
                            : Via_icon
            }
        };
    };

    setSubRouteState(item) {
        if (this.state.subRoutes.length > 50) {
            return;
        }
        this.setState({
            subRoutes: [...this.state.subRoutes, item],
            subRouteId: this.state.subRouteId + 1
        });
    }

    handleRemoveSubRoute(key) {
        this.removeLayer('Via', key);
        const filteredList = this.state.subRoutes.filter(item => item.key !== key);
        this.setState(
            {
                subRoutes: filteredList
            },
            () => {}
        );
    }
    switchLayers() {
        if (this.props.layers && this.props.layers.length > 2) {
            let layers = this.props.layers;
            let fromLayer = layers.find(x => x.id === 'Fra0');
            let toLayer = layers.find(x => x.id === 'Til1');

            let toPoint = this.defineMarker(
                fromLayer.source.data.geometry.coordinates[1],
                fromLayer.source.data.geometry.coordinates[0],
                {name: 'Til', index: 1}
            );

            let fromPoint = this.defineMarker(
                toLayer.source.data.geometry.coordinates[1],
                toLayer.source.data.geometry.coordinates[0],
                {name: 'Fra', index: 0}
            );
            let oldToValue = sessionStorage.getItem('Til1');
            let oldFromValue = sessionStorage.getItem('Fra0');

            sessionStorage.setItem('Fra0', oldToValue);
            sessionStorage.setItem('Til1', oldFromValue);
            this.props.setAllLayers([toPoint, fromPoint]);
        }
    }
    removeLayer(label, index) {
        const layer = this.props.layers.find(layer => layer.id === label + index);
        if (layer) {
            sessionStorage.removeItem(label + index);
            this.props.setAllLayers([layer], false);
            let stateObj = {};
            if (layer.id === 'Fra0' || layer.id === 'Til1') {
                stateObj.route = null;
            }
            if (this.state.fromMarkerToolTip && label + index === 'Fra0') {
                stateObj.fromMarkerToolTip = null;
            }
            if (this.state.toMarkerToolTip && label + index === 'Til1') {
                stateObj.toMarkerToolTip = null;
            }
            if (this.state.subMarkerToolTip && label === 'Via') {
                let markers = JSON.parse(JSON.stringify(this.state.subMarkerToolTip));
                let filteredList = markers.filter(x => x.id !== label + index);
                stateObj.subMarkerToolTip = filteredList;
            }
            this.setState(stateObj);
        }
    }
    reset() {
        this.setState({
            subRouteId: 0,
            subRoutes: [],
            fromMarkerToolTip: null,
            toMarkerToolTip: null,
            viaMarkerToolTip: null
        });
        sessionStorage.clear();
        this.props.resetAllLayers();
    }
    createLabelSearchProps(label, index) {
        return {
            labelName: label,
            targets: this.state.targets,
            placeholder: this.state.placeholder,
            hitSelected: this.getResultFormatted,
            index: index,
            getCoordinateResult: this.getCoordinateResult,
            setSnackBarContent: this.props.setSnackBarContent
        };
    }
    createRoutePlannerProps() {
        return {
            requiredSearchLabels: this.state.requiredSearchLabels,
            subRoutes: this.state.subRoutes
        };
    }

    render() {
        let routePlannerProps = this.createRoutePlannerProps();
        let renderRoute = this.state.route && this.props.layers.find(layer => layer.id === 'route');
        return (
            <React.Fragment>
                <RoutePlanner
                    {...routePlannerProps}
                    createLabelSearchProps={this.createLabelSearchProps}
                    switchLayers={this.switchLayers}
                    whenClearingResults={this.removeLayer}
                    handleRemoveSubRoute={this.handleRemoveSubRoute}
                    setSubRouteState={this.setSubRouteState}
                    subRouteId={this.state.subRouteId}
                    resetAllLayers={this.reset}
                    marker={this.props.marker}
                    fromMarkerToolTip={this.state.fromMarkerToolTip}
                    toMarkerToolTip={this.state.toMarkerToolTip}
                    subMarkerToolTip={this.state.subMarkerToolTip}
                    zoomOnClick={this.props.zoomOnClick}
                    setSnackBarContent={this.props.setSnackBarContent}
                />
                <div className={'routeResult ' + (renderRoute ? 'show' : 'hide') + ' rowContainer'}>
                    <div className="routeResultDiv">
                        <div>
                            <img src={linjal_icon} alt="linjal-icon" />
                            <b>Avstand:</b>
                            <p>
                                {renderRoute
                                    ? getDistanceString(this.state.route.CostList[0].Cost)
                                    : null}
                            </p>
                        </div>
                        <div className="ruteDescriptionBtnDiv" title="Rutebeskrivelse">
                            <NkaIconButton
                                icon={
                                    this.props.routeDescriptionVisibility
                                        ? 'venstrepil'
                                        : 'hoyrepil'
                                }
                                onClick={() => {
                                    this.props.toggleRouteDescription();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={
                        'routeErrorMessage ' + (this.state.routeErrorMessage ? 'show' : 'hide')
                    }>
                    <p>{this.state.routeErrorMessage}</p>
                </div>
            </React.Fragment>
        );
    }
}

RoutePlannerComponent.propTypes = exact({
    setAllLayers: PropTypes.func.isRequired,
    setRouteResults: PropTypes.func.isRequired,
    theme: PropTypes.string,
    toggleRouteDescription: PropTypes.func,
    layers: PropTypes.array,
    resetAllLayers: PropTypes.func,
    marker: PropTypes.object,
    setDraggedLayers: PropTypes.func,
    setLoading: PropTypes.func,
    routeDescriptionVisibility: PropTypes.bool,
    externalSubRoute: PropTypes.object,
    setMarker: PropTypes.func,
    zoomOnClick: PropTypes.func,
    setSnackBarContent: PropTypes.func
});

export default RoutePlannerComponent;
