import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import './routePlanner.css';
import NkaIconButton from 'nka-icon-button';
import 'nka-icon-button/dist/style.css';
import 'nkm-norkart-search/dist/style.css'; // import this from dist, will be copied there on change
import LabelSearch from 'components/LabelSearch/LabelSearchContainer';

class RoutePlanner extends Component {
    references = [];
    constructor(props) {
        super(props);
        this.searcBoxesRef = React.createRef();
        this.state = {
            fromLabel: 'Fra',
            fromId: 0,
            toLabel: 'Til',
            toId: 1,
            marker: null
        };
        this.addNewRow = this.addNewRow.bind(this);
        this.reset = this.reset.bind(this);
        this.setRef = this.setRef.bind(this);
    }

    componentDidUpdate(prevProps, prevStates) {

        if (this.props.marker !== prevProps.marker) {
            this.setState({marker: this.props.marker});
        }
    }

    switchLayers() {
        this.setState({
            fromLabel: this.state.toLabel,
            toLabel: this.state.fromLabel,
            toId: this.state.fromId,
            fromId: this.state.toId
        });
        this.props.switchLayers();
    }
    setRef = ref => {
        this.references.push(ref);
    };

    addNewRow = obj => {
        let key = obj.key;
        let labelSearchProps = this.props.createLabelSearchProps(obj.text, key);
        let marker = this.props.subMarkerToolTip ? this.props.subMarkerToolTip.find(x => x.id === obj.text + key) : null;
       
        return (
            <div key={key} className="rowContainer">
                <LabelSearch
                    {...labelSearchProps}
                    setRef={this.setRef}
                    marker= {this.state.marker}
                    markerToolTip={marker}
                    zoomOnClick={this.props.zoomOnClick}
                    whenClearingResults={this.props.whenClearingResults}>
                    <div className="column3">
                        <NkaIconButton
                            styleattributes={'border'}
                            icon="soppelbotte"
                            onClick={() => this.props.handleRemoveSubRoute(key)}
                        />
                    </div>
                </LabelSearch>
            </div>
        );
    };

    reset() {
        if (this.references && this.references.length > 0) {
            for (let index = 0; index < this.references.length; index++) {
                const element = this.references[index];
                if (element) {
                    element.clearResults();
                    this.props.resetAllLayers();
                }
            }
        }
    }
    render() {
        return (
            <div id="routeplanner">
                <div ref = {this.searcBoxesRef} id = "searchBoxes" className={this.props.subRoutes.length > 2 ? 'scroll' : null}>
                    <LabelSearch
                        key={0 + 'Fra'}
                        {...this.props.createLabelSearchProps(this.state.fromLabel, this.state.fromId)}
                        whenClearingResults={this.props.whenClearingResults}
                        marker= {this.state.marker}
                        markerToolTip={this.props.fromMarkerToolTip}
                        zoomOnClick={this.props.zoomOnClick}
                        setRef={this.setRef}
                    />
                    {this.props.subRoutes.map(subroute => this.addNewRow(subroute))}
                    <LabelSearch
                        key={1 + 'Til'}
                        {...this.props.createLabelSearchProps(this.state.toLabel, this.state.toId)}
                        setRef={this.setRef}
                        whenClearingResults={this.props.whenClearingResults}
                        markerToolTip={this.props.toMarkerToolTip}
                        zoomOnClick={this.props.zoomOnClick}
                        marker= {this.state.marker}
                    />
                </div>
                <div className="rowContainer">
                    <label className="column1">
                        {/*<Icon icon={'punktmarkoer'} size="1" />*/}
                    </label>
                    <div className="iconContainer column2">
                        <NkaIconButton
                            icon="pluss"
                            title="Legg til via-punkt"
                            onClick={() => {
                                this.props.setSubRouteState({
                                    text: 'Via',
                                    key: this.props.subRouteId
                                });
                            }}
                        />

                        <NkaIconButton
                            title="Snu rute"
                            icon="snu_rettning"
                            onClick={() => this.switchLayers()}
                        />
                    </div>
                    <div className='column3 resetBtn'>
                        <NkaIconButton
                            icon="soppelbotte"
                            title="Nullstill alle felt"
                            onClick={this.reset}/>
                    </div>
                </div>
            </div>
        );
    }
}

RoutePlanner.propTypes = exact({
    requiredSearchLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    subRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    createLabelSearchProps: PropTypes.func.isRequired,
    handleRemoveSubRoute: PropTypes.func.isRequired,
    setSubRouteState: PropTypes.func.isRequired,
    subRouteId: PropTypes.number.isRequired,
    whenClearingResults: PropTypes.func,
    switchLayers: PropTypes.func,
    resetAllLayers: PropTypes.func,
    marker: PropTypes.object,
    fromMarkerToolTip: PropTypes.object,
    toMarkerToolTip: PropTypes.object,
    subMarkerToolTip: PropTypes.array,
    zoomOnClick: PropTypes.func,
    setSnackBarContent: PropTypes.func
});

export default RoutePlanner;
