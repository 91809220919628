import React from 'react';
import PropTypes from 'prop-types';
import NKSpinnerLib from 'nka-spinner';

const getColor = (color, globalColor) => {
    if (color === 'nk-green' || !color) {
        return globalColor; //becomes black and grey if customer version
    } else {
        return color;
    }
};

const NKSpinner = ({
    color,
    globalColor,
    size,
    className
}) => {
    return (
        <NKSpinnerLib
            className={className}
            highlightColor={getColor(color, globalColor)}
            backgroundColor={'#000000'}
            size={size}
        />
    );
};

NKSpinner.defaultProps = {
    mode: 'dark',
    className: '',
    size: 1
};

NKSpinner.propTypes = {
    color: PropTypes.string,
    globalColor: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string
};

export default NKSpinner;