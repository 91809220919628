import React, {Component} from 'react';
import './App.css';
import './main.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import MainContent from 'scenes/protected/MainContent/MainContent';
import PrivateRoute from './PrivateRoute';
import PageNotFound from 'scenes/open/PageNotFound';
import LoginComponent from 'scenes/open/Login/LoginContainer';


class App extends Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>

                        <PrivateRoute path="/" exact component={MainContent} />
                        <Route component={PageNotFound} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
