import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import Login from './Login';
import norkartIdJs from 'util/authService';
import {Redirect} from 'react-router-dom';

class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false
        };
        this.logIn = this.logIn.bind(this);
    }
    componentDidMount() {
        this.logIn();
    }
    async logIn() {
        let isLoggedIn = await norkartIdJs.isAuthenticated();
        if (isLoggedIn === true) {
            this.setState({loggedIn: isLoggedIn});
        } else {
            await norkartIdJs.logIn();
            isLoggedIn = await norkartIdJs.isAuthenticated();
            if (isLoggedIn === true) {
                this.setState({loggedIn: isLoggedIn});
            }
        }
    }
    render() {
        const {nextLocation} = this.props;
        if (this.state.loggedIn) {
            return <Redirect to={nextLocation} />;
        }

        return <Login isLoading={!this.state.loggedIn} />;
    }
}

LoginContainer.propTypes = exact({
    nextLocation: PropTypes.string.isRequired
});

export default LoginContainer;
