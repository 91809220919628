import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import './routeDescriptionLabel.css';
import NkaIcon from 'nka-icons';

import {getTimeString, getDistanceString} from 'util/timeHandler';

const RouteDescriptionLabel = props => {
    const setLabel = routeInfo => {
        let junctionInfo = routeInfo.InfoList.find(x => x.Type === 'JunctionInfo');
        let roundaboutInfo = routeInfo.InfoList.find(x => x.Type === 'RoundaboutInfo');
        let uTurnInfo = routeInfo.InfoList.find(x => x.Type === 'UTurnInfo');
        let ferryInfo = routeInfo.InfoList.find(x => x.Type === 'FerryInfo');
        let roadInfo = routeInfo.InfoList.find(x => x.Type === 'RoadInfo');
        let terminalInfo = routeInfo.InfoList.find(x => x.Type === 'TerminalInfo');

        let label = {
            icon: 'advarsel_roed',
            index: props.index,
            name: null,
            coordinates: routeInfo,
            time: getTimeString(routeInfo.CostList.find(x => x.Name.includes('time')).Cost),
            distance: getDistanceString(
                routeInfo.CostList.find(x => x.Name.includes('distance')).Cost
            )
        };

        if (roadInfo) {
            let name = roadInfo.Route ? roadInfo.Route + '/' + roadInfo.Name : roadInfo.Name;
            label.icon = 'rett-frem';
            label.name = name ? name : 'Fant ikke navn';
        }

        if (terminalInfo) {
            label.icon = terminalInfo.IconHint ? terminalInfo.IconHint : 'punktmarkoer';
            if (label.icon === 'start-rute') {
                label.name = 'Start';
            } else if (label.icon === 'slutt-rute') {
                label.name = 'Slutt';
            } else {
                label.name = 'Via';
            }
            return label;
        }

        if (roundaboutInfo) {
            label.icon = 'rundkjoring';
            label.name = label.name
                ? 'ta ' +
                  roundaboutInfo.ExitNumber +
                  '. avkjøring i rundkjøringen inn på ' +
                  label.name
                : 'ta ' + roundaboutInfo.ExitNumber + '. avkjøring i rundkjøringen';
            return label;
        }
        if (uTurnInfo) {
            label.icon = 'u-sving';
            label.name = label.name ? 'Ta en u-sving inn på ' + label.name : null;
            return label;
        }
        if (ferryInfo) {
            label.icon = 'ferge';
            label.name = label.name ? 'Ferje til ' + label.name : null;
            if (ferryInfo.EnterExit === 'Exit') {
                label.name = 'Forlat ferje';
            }
            return label;
        }

        if (junctionInfo) {
            label.icon = junctionInfo.IconHint ? junctionInfo.IconHint : 'advarsel_roed';
            if (label.icon) {
                if (label.icon === 'svakt-venstre') {
                    label.name = label.name
                        ? 'Sving svakt til venstre, inn på ' + label.name
                        : 'Sving svakt til venstre';
                } else if (label.icon === 'venstre') {
                    label.name = label.name
                        ? 'Sving til venstre, inn på ' + label.name
                        : 'Sving til venstre';
                } else if (label.icon === 'skarpt-venstre') {
                    label.name = label.name
                        ? 'Sving skarpt til venstre, inn på ' + label.name
                        : 'Sving skarpt til venstre';
                } else if (label.icon === 'svakt-hoyre') {
                    label.name = label.name
                        ? 'Sving svakt til høyre, inn på ' + label.name
                        : 'Sving svakt til høyre';
                } else if (label.icon === 'hoyre') {
                    label.name = label.name
                        ? 'Sving til høyre, inn på ' + label.name
                        : 'Sving til høyre';
                } else if (label.icon === 'skarpt-hoyre') {
                    label.name = label.name
                        ? 'Sving skarpt til høyre, inn på ' + label.name
                        : 'Sving skarpt til høyre';
                }
            }
        }
        return label;
    };
    const onHover = (lat, long) => {
        const coordinates = {
            lat: lat,
            lng: long
        };
        props.onLabelHover(coordinates);
    };
    const onClick = (lat, long) => {
        const coordinates = {
            lat: lat,
            lng: long
        };
        props.onLabelClick(coordinates);
    };
    const renderLabel = routeInfo => {
        let label = setLabel(routeInfo);
        return (
            <div
                className={'list-item'}
                onMouseEnter={() => {
                    onHover(props.routeInfo.Y, props.routeInfo.X);
                }}
                onClick={() => {
                    onClick(props.routeInfo.Y, props.routeInfo.X);
                }}>
                <div className='list-item-icon'>{<NkaIcon icon={label.icon} />}</div>
                <div className={'labelInfo'}>
                    <div>{label.name}</div>
                </div>
            </div>
        );
    };

    return <div>{props.routeInfo && renderLabel(props.routeInfo)}</div>;
};

RouteDescriptionLabel.propTypes = exact({
    routeInfo: PropTypes.object.isRequired,
    index: PropTypes.number,
    onLabelHover: PropTypes.func,
    onLabelClick: PropTypes.func
});

export default RouteDescriptionLabel;
