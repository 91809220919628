import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

export function SuccessSnackbarContent(props) {
    const {classes, className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[props.variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className) + ' snackbar-popup'}
            aria-describedby="client-snackbar"
            message={
                <span
                    id="client-snackbar"
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <Icon
                        style={{
                            opacity: 0.9,
                            fontSize: 20
                        }}
                    />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CloseIcon
                        style={{
                            opacity: 0.9,
                            fontSize: 20
                        }}
                    />
                </IconButton>
            ]}
            {...other}
        />
    );
}

SuccessSnackbarContent.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};
