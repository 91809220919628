import Axios from 'axios';
import {mockRoute2} from 'util/mockApiService';
import getSetting from 'getSetting';

const defaultSearchOperation = 'Distance';
const BASE_URL = 'https://www.webatlas.no/WAAPI-Ferd/Route/Laanekassen';
//const BASE_URL_TEST = 'http://tvm-webatlashar/waapi-ferd/Route/Simple';


const extend = (to, from) => {
    for (var key in from) {
        if (from.hasOwnProperty(key)) {
            to[key] = from[key];
        }
    }
    return to;
};

const headers = extraHeaders => {
    return extend({Accept: 'application/json; charset=utf-8'}, extraHeaders);
};

export const fetchRoute = async (layers, params = null) => {
    let api_key = getSetting('REACT_APP_FERD_API_KEY');
    let extraHeaders = {
        'X-WAAPI-Token': api_key
    };
    let instance = createNewClient(headers(extraHeaders));
    return instance.post(`${defaultSearchOperation}/`, layers);
};

export const fetchMockRoute = async (points, params = null) => {
    return mockRoute2;
};

const createNewClient = headers => {
    return Axios.create({
        baseURL: BASE_URL,
        timeout: 80000,
        headers: headers,
        responseType: 'json'
    });
};

//gå gjennom objektet med en for for å finne "coordinates"
//custom proptypes for array med geojson
export const createRequestObject = (layers, zoomLevel = 20) => {
    let fromObj = layers.find(layer => layer.id === 'Fra0');
    let toObj = layers.find(layer => layer.id === 'Til1');

    let viaLayers = layers.filter(layer => layer.id.includes('Via'));
    let via = viaLayers.map(point => {
        return {
            X: point.source.data.geometry.coordinates[0],
            Y: point.source.data.geometry.coordinates[1]
        };
    });

    let obj = {
        Start: {
            X: fromObj.source.data.geometry.coordinates[0],
            Y: fromObj.source.data.geometry.coordinates[1]
        },
        Stop: {
            X: toObj.source.data.geometry.coordinates[0],
            Y: toObj.source.data.geometry.coordinates[1]
        },
        Via: via,
        SrsId: 4326,
        OptimizeRoute: true,
        ReturnGeometry: true,
        ZoomLevel: zoomLevel
    };
    return obj;
};
