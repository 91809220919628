import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import NkaSpinner from 'nka-spinner';

const Login = props => {
    let {isLoading} = props;
    return <React.Fragment>{isLoading && <NkaSpinner />}</React.Fragment>;
};

Login.propTypes = exact({
    isLoading: PropTypes.bool.isRequired
});

export default Login;
