import React, {Component} from 'react';
import NkaPopup from 'nka-popup';
import 'nka-popup/dist/style.css';
import NkaIconButton from 'nka-icon-button';
import 'nka-icon-button/dist/style.css';
import MapComponent from 'components/Map/Map';
import './mainContent.css';
import NkaSidebar from 'nka-sidebar';
import 'nka-sidebar/dist/style.css';
import RoutePlanner from 'components/RoutePlanner/RoutePlannerContainer';
import TabMenu from 'components/TabMenu/TabMenu';
import 'components/TabMenu/tabMenu.css';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import RouteDescription from 'components/RouteDescription/RouteDescription';
import norkartlogo from '../../.././App/Norkartlogo.PNG';
import laanekassenlogo from '../../.././App/LK_Logo_liggende_farger_u_bakgr.svg';
import NkaIcon from 'nka-icons';
import {SuccessSnackbarContentWrapper} from '../../../components/SnackbarContent/SuccessSnackbarContentWrapper';

import Snackbar from '@material-ui/core/Snackbar';

class MainContent extends Component {
    constructor() {
        super();
        this.state = {
            visible: false,
            theme: 'light',
            popupContent: 'dette er en testtekst',
            layers: [],
            points: {},
            route: null,
            hoverCoordinates: null,
            clickCoordinates: null,
            hideRouteDescription: false,
            marker: null,
            isLayerDragged: false,
            loadRoute: false,
            externalSubRoute: null,
            snackbarIsOpen: false,
            snackbarCoordinates: null,
            snackbarSuccess: 'success'
        };
        this.setVisibility = this.setVisibility.bind(this);
        this.setPopupContent = this.setPopupContent.bind(this);
        this.getSideBarFooterProps = this.getSideBarFooterProps.bind(this);
        this.toggleRouteDescription = this.toggleRouteDescription.bind(this);
        this.setAllLayers = this.setAllLayers.bind(this);
        this.resetAllLayers = this.resetAllLayers.bind(this);
        this.setRouteResults = this.setRouteResults.bind(this);
        this.onLayerDragged = this.onLayerDragged.bind(this);
        this.setDraggedLayers = this.setDraggedLayers.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setSubRoutes = this.setSubRoutes.bind(this);
        this.setMarker = this.setMarker.bind(this);
        this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
        this.setSnackBarContent = this.setSnackBarContent.bind(this);
    }
    resetAllLayers() {
        this.setState({
            layers: []
        });
    }
    setAllLayers = (layers, add = true) => {
        if (add) {
            this.setState(state => ({
                layers: [
                    ...state.layers.filter(
                        existingLayer => !layers.some(x => existingLayer.id === x.id)
                    ),
                    ...layers
                ]
            }));
        } else {
            this.setState(
                {
                    layers: [
                        ...this.state.layers.filter(
                            existingLayer => !layers.some(x => existingLayer.id === x.id)
                        )
                    ]
                },
                () => {
                    if (
                        !(
                            this.state.layers.some(layer => layer.id === 'Fra0') &&
                            this.state.layers.some(layer => layer.id === 'Til1')
                        )
                    ) {
                        const route = this.state.layers.find(layer => layer.id === 'route');
                        if (route) {
                            this.setAllLayers([route], false);
                        }
                    }
                }
            );
        }
    };

    setVisibility() {
        if (this.state.visible) {
            this.setState({
                visible: false
            });
        } else {
            this.setState({
                visible: true
            });
        }
    }
    onLayerDragged(marker) {
        this.setState({
            marker: marker,
            isLayerDragged: true
        });
    }
    setMarker(marker) {
        this.setState({
            marker: marker,
            isLayerDragged: true
        });
    }
    setSubRoutes(latlng) {
        this.setState({externalSubRoute: latlng});
    }
    setDraggedLayers(bool, resetMarker) {
        let stateObj = {
            isLayerDragged: bool
        };
        if (resetMarker) {
            stateObj.marker = null;
        }
        this.setState(stateObj);
    }
    setPopupContent(content) {
        this.setState({
            popupContent: content
        });
    }

    getSideBarFooterProps() {
        return {
            className: 'alertBtn',
            onClick: () => {
                this.setPopupContent('dette skrev jeg her');
                this.setVisibility();
            },
            icon: 'advarsel',
            theme: 'light'
        };
    }

    setRouteResults = route => {
        if (route) {
            const routeLayer = this.defineRouteLayer(route);
            if (routeLayer) {
                this.setState({
                    route: route,
                    layers: [
                        ...this.state.layers.filter(
                            existingLayer => existingLayer.id !== routeLayer.id
                        ),
                        routeLayer
                    ]
                });
            }
        } else {
            this.setState({
                route: route
            });
        }
    };
    setSnackBarContent(coordinates, success = true) {
        this.setState({
            snackbarCoordinates: coordinates,
            snackbarSuccess: success ? 'success' : 'error',
            snackbarIsOpen: true
        });
    }

    setLoading() {
        this.setState({
            loadRoute: !this.state.loadRoute
        });
    }

    setHoverCoordinates = coordinates => {
        this.setState({
            hoverCoordinates: coordinates
        });
    };
    setClickCoordinates = coordinates => {
        this.setState({
            clickCoordinates: coordinates
        });
    };

    defineRouteLayer(route) {
        if (this.state.layers.length < 1) {
            return null;
        }
        return {
            id: 'route',
            type: 'line',
            source: {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: route.RouteGeometry
                }
            },
            properties: {
                beforeLayerId: this.state.layers[0].id,
                boundingBox: route.BoundingBox
            },
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'fill-color': '#9b4c9f',
                'line-width': 6
            }
        };
    }
    handleSnackBarClose() {
        this.setState({snackbarIsOpen: false});
    }

    toggleRouteDescription = () => {
        this.setState({hideRouteDescription: !this.state.hideRouteDescription});
    };

    render() {
        return (
            <React.Fragment>
                <div id="page">
                    <NkaPopup
                        theme={this.state.theme}
                        visible={this.state.visible}
                        setVisibility={this.setVisibility}>
                        {/*this.state.popupContent*/}
                        <TabMenu
                            content={[
                                {
                                    header: 'Hva?',
                                    content: (
                                        <React.Fragment>
                                            <div>
                                                <b>{'Søk'}</b>
                                                <p>
                                                    {
                                                        'Det er mulig å søke på adresser, matrikkelnummre eller koordinater (bredde- og lengdegrader eller øst- og nordkoordinater). Alle koordinater som vises i klienten vil være angitt i UTM-sone 33 (EUREF89)'
                                                    }
                                                </p>
                                                <li>
                                                    {'Eksempel på adresse: '}
                                                    <i>{'Løkketangen 20A'}</i>
                                                </li>
                                                <li>
                                                    {'Eksempel på matrikkelnummer: '}
                                                    <i>{'Bærum 7/17'}</i>
                                                </li>
                                                <li>
                                                    {'Eksempel på koordinater (bredde-, lengdegrad): '}
                                                    <i>{'59.89158 10.52305'}</i>
                                                </li>
                                                <li>
                                                    {'Eksempel på koordinater (East, North): '}
                                                    <i>{'249597.69 6647806.74'}</i>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.kommunekart.com/Hjelp/index.html#!Documents/eksemplerpkoordinatsystemersketsttterogtilhrendekoordinatsett.htm">Les mer</a>
                                                </li>
                                                <br />
                                                
                                                
                                                <i>
                                                    {
                                                        'Ved søk på koordinater vil det ikke komme forslag. Trykk enter for å søke'
                                                    }
                                                </i>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                            <div className="i-add-points">
                                                <b>{'Legg til til-, fra- eller via-punkt'}</b>
                                                <ul>
                                                    <li>
                                                        <p>
                                                            <NkaIcon icon="start-rute" />
                                                            {' Rutens start'}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <NkaIcon icon="punktmarkoer" />
                                                            {' Via-punkter på veien mellom A og B'}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <NkaIcon icon="slutt-rute" />
                                                            {' Rutens slutt'}
                                                        </p>
                                                    </li>
                                                </ul>
                                                <p>
                                                    {
                                                        'Rutepunktene kan legges til ved å bruke søkefeltene eller ved å høyreklikke i kartet. Trykk '
                                                    }
                                                    <NkaIcon icon="pluss" />
                                                    {' for å få opp søkefelt for viapunkter.'}
                                                </p>
                                                <p>
                                                    {
                                                        'Koordinater til adressesøk kan ses ved å føre musepekeren over '
                                                    }{' '}
                                                    <NkaIcon icon="punktmarkoer" />{' '}
                                                    {' i sidemenyen'}
                                                </p>
                                                <p>
                                                    {'Venstre-klikk + CTRL-knappen på '}
                                                    <NkaIcon icon="punktmarkoer" />
                                                    {' vil kopiere koordinatene til punktet.'}
                                                </p>
                                                <i>
                                                    {
                                                        'Punkter som er lagt til ved å høyreklikke i kartet vises i søkefeltene med øst-nord-koordinater'
                                                    }
                                                </i>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                            <div>
                                                <b>{'Endre til-, fra- eller via-punkt'}</b>
                                                <p>
                                                    {
                                                        'Søk på nytt, eller dra og slipp markøren i kartet.'
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <br />
                                            <div>
                                                <b>{'Slett til, fra- eller via-punkt'}</b>
                                                <p>
                                                    {'Trykk '}
                                                    <b>{'x'}</b>
                                                    {
                                                        ' i søkefeltet for å slette punkt. Viapunkter kan også slettes ved å trykke på '
                                                    }
                                                    <NkaIcon icon="soppelbotte" />
                                                    {' til høre for søkefeltet. Bruk '}
                                                    <NkaIcon icon="soppelbotte" />
                                                    {
                                                        ' under søkefeltene for å slette alle punkter.'
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <br />
                                            <div>
                                                <b>{'Snu ruten'}</b>
                                                <p>
                                                    {
                                                        'Fordi enveiskjøringer og svingrestriksjoner kan forekomme kan det hende at A til B ikke gir samme resultater som B til A. Derfor er det mulig å snu ruten. Trykk på '
                                                    }
                                                    <NkaIcon icon="snu_rettning" />
                                                    {
                                                        ' under søkefeltene for å invertere rekkefølgen på rutepunktene.'
                                                    }
                                                </p>
                                                <br />
                                                <br />
                                            </div>
                                            <div>
                                                <b>
                                                    {'Se rute, ruteinformasjon og rutebeskrivelse'}
                                                </b>
                                                <p>
                                                    {
                                                        'Når både et til- og fra-punkt er definert vil ruten vises i kartet, og informasjon om ruten vil dukke opp i sidemenyen.'
                                                    }
                                                </p>
                                                <p>
                                                    {'Velg '}
                                                    <NkaIcon icon="hoyrepil" />
                                                    {' for å se rutebeskrivelsen. Velg '}
                                                    <NkaIcon icon="venstrepil" />
                                                    {' for å gjemme rutebeskrivelsen.'}
                                                </p>
                                                <p>
                                                    {
                                                        'Trykk eller før musepekeren over feltene i rutebeskrivelsen for å visualisere punktet for veiangivelsen i kartet.'
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <br />
                                            <div>
                                                <b>{'Se og kopiere koordinater'}</b>
                                                <p>
                                                    {
                                                        'Øst-nord-koordinatene til et punkt kommer til syne ved å føre musepekeren over markøren i kartet eller i sidemenyen. Kopier koordinatene ved å trykke på markør i sidemenyen og på samme tid holde nede CTRL (venstreklikk + CTRL).'
                                                    }
                                                </p>
                                            </div>
                                        </React.Fragment>
                                    )
                                },
                                {
                                    header: 'Hvordan?',
                                    content: (
                                        <React.Fragment>
                                            <div>
                                                <h3>{'Hvordan beregnes korteste vei?'}</h3>
                                                <p>
                                                    {
                                                        'Ruteplanleggeren finner den optimale veien mellom endepunkter i veinettverket. Optimal vil her si at den vil finne den veien som er kortest, raskest eller «billigst» på andre måter. For lånekassen er programmet satt opp til å finne den '
                                                    }{' '}
                                                    {<i>{'korteste'}</i>}{' '}
                                                    {
                                                        ' veien. Det vil si at ruteplanleggeren normalt ikke vil velge en rute som en vanlig trafikant ville ha benyttet, den vil for eksempel ikke følge en rask hovedvei hvis en boligfeltgate, skogsvei eller en tett trafikkert bygate skulle vise seg å være kortere. De fleste ruteplanleggere man finner på nettet (Google Maps, Gule sider, 1881, Finn.no m.f.) vil normalt velge raskeste vei, som gir helt andre resultater.'
                                                    }
                                                    <br />
                                                    <br />
                                                    {
                                                        ' Ruteplanleggeren tar hensyn til enveiskjøringer, svingrestriksjoner (u-sving forbudt, forbudt å svinge til venstre osv…) og gater med gjennomkjøring forbudt. Dette medfører at korteste rute fra punkt A til punkt B ikke nødvendigvis er den samme som den motsatte veien, fra punkt B til punkt A. Det medfører også at små endringer i plasseringen av endepunktene av og til kan medføre overraskende endringer i hva som er korteste rute mellom dem. Om et endepunkt flyttes ut av et område med gjennomkjøring forbudt så kan ruten nødvendigvis ikke gå gjennom dette. Om et endepunkt kommer inn på en enveiskjørt gate så må denne kjøreretningen følges og så videre.'
                                                    }
                                                    <br />
                                                    <br />
                                                    {
                                                        ' Dataene som utgjør beregningsgrunnlaget for ruteberegneren oppdateres to ganger i året, én gang på våren og én gang på høsten'
                                                    }
                                                </p>
                                            </div>
                                        </React.Fragment>
                                    )
                                },
                                {
                                    header: 'Kontakt',
                                    content: (
                                        <React.Fragment>
                                            <div>
                                                <div>
                                                    <img src={norkartlogo} alt="logo" id="norkartlogo" style={{width: 200}}/>
                                                </div>
                                                <p>
                                                    {
                                                        'Norkart AS'
                                                    }
                                                </p>
                                                <div>
                                                    {
                                                        'Hovedkontor Sandvika'
                                                    }
                                                    <br/>
                                                    {
                                                        'Postboks 145, Løkketangen 20A'
                                                    }
                                                    <br/>
                                                    {    
                                                        '1300 Sandvika'
                                                    }
                                                </div>
                                                <p>
                                                    {
                                                        'Telefon: 67 55 14 00'
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        'info@norkart.no'
                                                    }
                                                </p>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            ]}
                        />
                    </NkaPopup>
                    <div id="sidebar">
                        <NkaSidebar
                            header={
                                <div>
                                    <img src={laanekassenlogo} alt="tekst" id="laanekassenLogo" />
                                    <p id="reiseavstand">Reiseavstand</p>
                                </div>
                            }
                            footer={
                                <React.Fragment>
                                    <div>
                                        <NkaIconButton {...this.getSideBarFooterProps()} />
                                    </div>
                                    <div>
                                        <img src={norkartlogo} alt="logo" id="norkartlogo" />
                                    </div>
                                </React.Fragment>
                            }>
                            <div>
                                <RoutePlanner
                                    layers={this.state.layers}
                                    setAllLayers={this.setAllLayers}
                                    setRouteResults={this.setRouteResults}
                                    setDraggedLayers={this.setDraggedLayers}
                                    toggleRouteDescription={this.toggleRouteDescription}
                                    theme="light"
                                    marker={this.state.marker}
                                    resetAllLayers={this.resetAllLayers}
                                    setLoading={this.setLoading}
                                    externalSubRoute={this.state.externalSubRoute}
                                    routeDescriptionVisibility={this.state.hideRouteDescription}
                                    setMarker={this.setMarker}
                                    zoomOnClick={this.setClickCoordinates}
                                    setSnackBarContent={this.setSnackBarContent}
                                />
                            </div>
                        </NkaSidebar>
                    </div>
                    <div id="mapDiv">
                        {this.state.route && (
                            <RouteDescription
                                route={this.state.route}
                                show={this.state.hideRouteDescription}
                                onLabelHover={this.setHoverCoordinates}
                                onLabelClick={this.setClickCoordinates}
                            />
                        )}
                        <MapComponent
                            onLayerDragged={this.onLayerDragged}
                            setAllLayers={this.setAllLayers}
                            layers={this.state.layers}
                            isLayerDragged={this.state.isLayerDragged}
                            setSubRoutes={this.setSubRoutes}
                            markerCoordinates={this.state.hoverCoordinates}
                            zoomPoint={this.state.clickCoordinates}
                            loadRoute={this.state.loadRoute}
                        />
                    </div>
                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={this.state.snackbarIsOpen}
                        onClose={this.handleSnackBarClose}
                        autoHideDuration={2000}>
                        <SuccessSnackbarContentWrapper
                            variant={this.state.snackbarSuccess}
                            message={`${this.state.snackbarCoordinates}`}
                            onClose={this.handleSnackBarClose}
                        />
                    </Snackbar>
                </div>
            </React.Fragment>
        );
    }
}

MainContent.propTypes = exact({
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    staticContext: PropTypes.object
});

export default MainContent;
