const getTimeString = time => {
    if (time.toString().includes('e+')) {
        return '---';
    }
    let hours = Math.floor(time / 60);
    let min = Math.round(time % 60);
    if (hours > 0) {
        let timeString = ' timer ';
        if (hours === 1) {
            timeString = ' time ';
        }
        return hours + timeString + min + ' min';
    }

    return min + ' min';
};
const getDistanceString = distance => {
    let km = Math.floor(distance / 1000);
    let m = Math.round(distance % 1000);

    if (km > 0) {
        return km + ' km ' + m + ' m';
    }
    return m + ' m';
};

export {getDistanceString, getTimeString};
