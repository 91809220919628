import React, {Component} from 'react';
import 'nka-icon-button/dist/style.css';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import './satelliteBtn.css';
import kartimg from './kartimg.png';
import satimg from './satimg.png';

class SatelliteBtn extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="SatelliteBtn">
                    <div className={'btn ' + this.props.theme} onClick={this.props.changeMap}>
                        {this.props.satImg ? (
                            <img className="satImg" src={satimg} alt="Se satellitt" title='Bytt til hybridkart'/>
                        ) : (
                            <img className="kartImg" src={kartimg} alt="Se kart" title='Bytt til vanlig kart'/>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

SatelliteBtn.defaultProps = {
    theme: 'light',
    satImg: true
};

SatelliteBtn.propTypes = exact({
    theme: PropTypes.string,
    satImg: PropTypes.bool,
    changeMap: PropTypes.func.isRequired
});

export default SatelliteBtn;
