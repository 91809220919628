import React, {Component} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PropTypes from 'prop-types';
import './leafletMap.css';
import 'leaflet-webatlastile';
import getSetting from 'getSetting';
import exact from 'prop-types-exact';
import markericon from './icons/Via-icon.svg';
import proj4 from 'proj4';

class LeafletMap extends Component {
    constructor(props) {
        super(props);
        this._changeMapView = this._changeMapView.bind(this);
        this._drawMarker = this._drawMarker.bind(this);
        this._drawLayer = this._drawLayer.bind(this);
        this._enableInteractivity = this._enableInteractivity.bind(this);
        this._disableInteractivity = this._disableInteractivity.bind(this);
        this._setInteractiveMode = this._setInteractiveMode.bind(this);
        this._addControls = this._addControls.bind(this);
        this._addEvents = this._addEvents.bind(this);
        this._prepareDraggableLayer = this._prepareDraggableLayer.bind(this);
        this._moveFollowDraggableLayer = this._moveFollowDraggableLayer.bind(this);
        this._addBackgroundMap = this._addBackgroundMap.bind(this);
        this.allFeatures = [];
        this._layergroup = L.layerGroup();
        this.marker = null;
        this.geojson = null;
        this.interactive = true;
        this.followDraggableLayerGeometry = {};

        this.state = {
            mapLoaded: false
        };
    }

    componentDidMount() {
        var zoomLevel = this.props.zoomLevel;
        var centerCoords = this.props.centerCoords;
        var maptoken = getSetting('REACT_APP_SEARCH_API_KEY');
        this._map = new L.Map('map', {
            center: centerCoords,
            zoom: zoomLevel,
            zoomControl: false,
            minZoom: 4
        });
        this._map.invalidateSize(); //Making it possible to resize with css
        this._mapTiles = L.tileLayer
            .webatlas({apikey: maptoken, mapType: this._getMaptype()})
            .addTo(this._map);

        this._setInteractiveMode();
        this._map.on('click', event => {
            if (this.props.onClick && this.interactive) {
                this.props.onClick(event, this._map.getZoom());
            }
        });
        this._mapTiles.on('load', () => {
            if (this.state.mapLoaded) {
                return; //maptile load event is called twice so return to avoid calling code twice
            }
            this.setState({mapLoaded: true});
            //run callback if exist
            if (this.props.onMapLoaded) {
                this.props.onMapLoaded();
            }
            this._drawWmsLayers(this.props.wmsLayers);
            this._drawMarker(this.props.marker);
            //this._drawLayers(this.props.layers);

            //adding for draw functionality
            if (this.props.featureGroup) {
                if (this.props.featureGroupStyle) {
                    this.props.featureGroup.eachLayer(layer => {
                        layer.setStyle(this.props.featureGroupStyle);
                    });
                }
                this._map.addLayer(this.props.featureGroup);
            }
        });

        this._map.on('moveend', () => {
            const mapView = {
                centerCoords: this._map.getCenter(),
                zoomlevel: this._map.getZoom(),
                bounds: this._map.getBounds()
            };
            if (this.props.onMoveend) {
                this.props.onMoveend(mapView);
            }
        });
        if (this.props.navigationControls && this.props.navigationControls.show) {
            new L.control.zoom({
                position: this.props.navigationControls.options.replace('-', '')
            }).addTo(this._map);
        }
        this._addControls(this.props.controls);
        this._addEvents(this.props.events);

        if (this.props.onMapRightClick) {
            this._map.on('contextmenu', e => {
                var popupTest = L.popup().setContent(
                    '<div class = "leaflet-popup-content-wrapper"></div>'
                );
                popupTest.setLatLng(e.latlng).openOn(this._map);
                let truncatedLatLng = {
                    lat: Number(e.latlng.lat),
                    lng: Number(e.latlng.lng)
                };
                this.props.onMapRightClick(truncatedLatLng); //callback
                // console.log(e.latlng);
                // console.log(truncatedLatLng);
                if (this.props.rightClickMapPoupContent) {
                    // var popupTest = L.popup().setContent(this.props.rightClickMapPoupContent);
                    // popupTest.setLatLng(e.latlng).openOn(this._map);
                }
                //this._map.openPopup(popupTest);
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.wmsLayer && !nextProps.wmsLayer) {
            this.wmsLayer.remove();
        }
    }

    componentDidUpdate(prevProps) {
        this._map.invalidateSize(); //Making it possible to resize with css
        // quick sequence of updates in props can cause problems. should be fixed in the future
        if (this.state.mapLoaded) {
            if (this.props.layers !== prevProps.layers) {
                this._drawLayers(this.props.layers, prevProps.layers);
            }
            this._drawMarker(this.props.marker);

            this._drawWmsLayers(this.props.wmsLayers, prevProps.wmsLayers);
            if (this.props.recenterMap) {
                this._changeMapView();
            }
            if (prevProps.interactive !== this.props.interactive) {
                this._setInteractiveMode();
            }
            this._fitBounds();
        }
        if (this.props.featureGroup && this.props.featureGroupStyle) {
            this.props.featureGroup.eachLayer(layer => {
                layer.setStyle(this.props.featureGroupStyle);
            });
        }
        this._updateEvents(this.props.events, prevProps.events);

        //Adding optional drawable layer
        if (this.props.drawPolygon && this.props.drawPolygon.create) {
            this._createNewDrawPolygon(this.props.drawPolygon.succesfullyCreated);
        }

        if (this.props.maptype !== prevProps.maptype) {
            this._addBackgroundMap();
        }
    }

    componentWillUnmount() {
        if (this._map) {
            if (this.draggableLayer && this.marker && this.props.onLayerDragged) {
                const centerCoords = [this.marker.getLatLng().lat, this.marker.getLatLng().lng];
                this.props.onLayerDragged(
                    centerCoords,
                    this.followDraggableLayerGeometry.newGeom.geometry.coordinates
                ); //callback
            }
            this._map.remove();
        }
    }

    _getMaptype() {
        if (this.props.maptype === 'aerial') {
            return L.TileLayer.Webatlas.Type.AERIAL;
        } else if (this.props.maptype === 'hybrid') {
            return L.TileLayer.Webatlas.Type.HYBRID;
        } else {
            return L.TileLayer.Webatlas.Type.MEDIUM;
        }
    }

    _addBackgroundMap() {
        var maptoken = getSetting('REACT_APP_SEARCH_API_KEY');

        this._map.eachLayer(layer => {
            //only way to detect tile layer?
            if (layer._url) {
                this._map.removeLayer(layer);
            }
        });
        //this._map.removeLayer(L.tileLayer);
        this._mapTiles = L.tileLayer
            .webatlas({apikey: maptoken, mapType: this._getMaptype()})
            .addTo(this._map);
    }

    _drawMarker(marker) {
        if (this.marker) {
            this._map.removeLayer(this.marker);
        }
        if (marker != null) {
            const icon = L.icon({
                iconUrl: markericon,
                iconSize: [50, 50],
                iconAnchor: [25, 50]
            });
            this.marker = L.marker([marker.lat, marker.lng], {icon: icon}).addTo(this._map);
        }
    }

    _createNewDrawPolygon(callback) {
        var drawPol = new L.Draw.Polygon(this._map);
        drawPol.enable();
        callback(drawPol);
    }

    // _getBounds(coordinates) {
    //     var bounds = coordinates.reduce(function (bounds, coord) {
    //         return bounds.extend(coord);
    //     }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[1]));
    //     return bounds;
    // }

    _isArrayOfArray(array) {
        if (Array.isArray(array) && Array.isArray(array[0])) {
            return true;
        }

        return false;
    }

    _fitBounds() {
        if (this.props.fitBounds) {
            const options = this.props.fitBounds.options
                ? this.props.fitBounds.options
                : {padding: 0};
            const formattedOptions = {padding: [options.padding, options.padding]};
            const b = this.props.fitBounds.bounds;

            if (this._isArrayOfArray(b)) {
                // const boundsFormatted = this._getBounds(b);
                const boundsFormatted = L.latLngBounds(
                    b.map(c => {
                        return [c[1], c[0]];
                    })
                );
                this._map.fitBounds(boundsFormatted, formattedOptions);
            } else {
                const boundsFormatted = [[b[1], b[0]], [b[3], b[2]]];
                this._map.fitBounds(boundsFormatted, formattedOptions);
            }
        }
    }

    _setInteractiveMode() {
        this.props.interactive ? this._enableInteractivity() : this._disableInteractivity();
    }

    _disableInteractivity() {
        this._map.dragging.disable();
        this._map.touchZoom.disable();
        this._map.doubleClickZoom.disable();
        this._map.scrollWheelZoom.disable();
        this._map.boxZoom.disable();
        this._map.keyboard.disable();
        if (this._map.tap) {
            this._map.tap.disable();
        }
        document.getElementById('map').style.cursor = 'default';
    }

    _enableInteractivity() {
        this._map.dragging.enable();
        this._map.touchZoom.enable();
        this._map.doubleClickZoom.enable();
        this._map.scrollWheelZoom.enable();
        this._map.boxZoom.enable();
        this._map.keyboard.enable();
        if (this._map.tap) {
            this._map.tap.enable();
        }
        document.getElementById('map').style.cursor = 'grab';
    }

    _addControls(controls) {
        if (controls) {
            controls.forEach(control => this._map.addControl(control.control));
        }
    }

    _addEvents(events) {
        if (events) {
            events.forEach(event => this._map.on(event.type, event.callback));
        }
    }
    _updateEvents(events, prevEvents) {
        var eventsToAdd = this._addedDiffForEvents(prevEvents, events);
        var eventsToRemove = this._addedDiffForEvents(events, prevEvents);
        this._addEvents(eventsToAdd);
        this._removeEvents(eventsToRemove);
    }

    _removeEvents(events) {
        if (events) {
            events.forEach(event => this._map.off(event.type, event.callback));
        }
    }

    _addedDiffForEvents(original, updated) {
        var addedDiff = [];
        if (updated) {
            updated.forEach(updatedItem => {
                var isNew =
                    (original || []).filter(
                        originalItem =>
                            originalItem.type === updatedItem.type &&
                            originalItem.callback === updatedItem.callback
                    ).length === 0;
                if (isNew) {
                    addedDiff.push(updatedItem);
                }
            });
        }
        return addedDiff;
    }
    _addToMap(geoJson) {}

    _drawLayers(geojsons, prevGeojsons) {
        this._map.eachLayer(layer => {
            //only way to detect tile layer?
            if (!layer._url) {
                this._map.removeLayer(layer);
            }
        });

        if (geojsons) {
            geojsons.forEach(geojson => {
                if (geojson) {
                    if (geojson.hasOwnProperty('properties') && geojson.properties.draggable) {
                        this.centerPoint = [
                            geojson.source.data.geometry.coordinates[1],
                            geojson.source.data.geometry.coordinates[0]
                        ];
                        this._prepareDraggableLayer(geojson, this.centerPoint);
                    } else if (
                        geojson.hasOwnProperty('properties') &&
                        geojson.properties.followDraggable
                    ) {
                        this.followDraggableLayerGeometry.initialGeom = geojson.source.data;
                        if (this.followDraggableLayer) {
                            this.followDraggableLayer.clearLayers();
                        }
                        this.followDraggableLayer = L.geoJSON(
                            this.followDraggableLayerGeometry.initialGeom
                        ).addTo(this._map);
                    } else {
                        if (this.allFeatures[geojson.id]) {
                            this.allFeatures[geojson.id].clearLayers();
                        }
                        this.allFeatures[geojson.id] = this._drawLayer(geojson);
                    }
                }
            });
        }
    }
    _addPopupToMarker(marker, geojson) {
        if (geojson.properties.popupContent) {
            //marker.bindPopup(geojson.properties.popupContent);
        }
    }

    _addToolTipToMarker(marker, geojsonToolTip) {
        if (geojsonToolTip) {
            if (geojsonToolTip === 'latlng') {
                this._addCoordinatesToToolTip(marker, 'latlng');
            } else if (geojsonToolTip === 'UTM33') {
                this._addUTM33CoordToToolTip(marker);
            } else {
                marker.bindTooltip(geojsonToolTip, {className: 'leaflet-tooltip'});
            }
        }
    }

    _addUTM33CoordToToolTip(marker) {
        let latlng = marker.getLatLng().lat.toString() + ' ' + marker.getLatLng().lng.toString();
        let array = latlng.split(' ');
        let utmNumber = '+proj=utm +zone=33';
        let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
        let numberArray = [Number.parseFloat(array[0]), Number.parseFloat(array[1])];
        let utm33 = proj4(utmNumber, [
            Number.parseFloat(numberArray[1]),
            Number.parseFloat(numberArray[0])
        ]);
        marker.bindTooltip(utm33[0].toFixed(3) + 'E' + ' ' + utm33[1].toFixed(3) + 'N', {className: 'leaflet-tooltip'});
    }

    _addLatLngToToolTip(marker) {
        marker.bindTooltip([marker.getLatLng().lat, marker.getLatLng().lng].toString(), {
            className: 'leaflet-tooltip'
        });
    }

    _prepareDraggableLayer(draggableLayer, centerPoint) {
        let centerIcon = L.icon({
            iconUrl: 'https://d30y9cdsu7xlg0.cloudfront.net/png/45132-200.png',
            iconSize: [66, 66],
            iconAnchor: [33, 33]
        });

        if (draggableLayer.properties.iconUrl) {
            centerIcon = this._getLayerStyle(draggableLayer);
        }
        let name = 'draggableLayer';
        if (draggableLayer.properties.name) {
            name = draggableLayer.properties.name;
        }
        this.draggableLayer = L.marker(centerPoint, {
            icon: centerIcon,
            draggable: 'true',
            alt: name
        }).addTo(this._map);

        this._addPopupToMarker(this.draggableLayer, draggableLayer);
        this._addToolTipToMarker(this.draggableLayer, draggableLayer.properties.toolTip);

        this.draggableLayer.on(
            'drag',
            function (event) {
                if (this.followDraggableLayer) {
                    this.followDraggableLayer.clearLayers();
                }
                this.marker = event.target;
                const position = this.marker.getLatLng();
                this._moveFollowDraggableLayer(position);
            }.bind(this)
        );

        this.draggableLayer.on(
            'dragend',
            function (event) {
                if (this._map) {
                    if (this.draggableLayer && this.marker && this.props.onLayerDragged) {
                        const centerCoords = [
                            this.marker.getLatLng().lat,
                            this.marker.getLatLng().lng
                        ];
                        if (this.followDraggableLayerGeometry.length) {
                            this.props.onLayerDragged(
                                centerCoords,
                                this.followDraggableLayerGeometry.newGeom.geometry.coordinates
                            ); //callback
                        } else {
                            this._addToolTipToMarker(
                                this.marker,
                                this.marker.getToolTip ? true : false
                            );
                            this.props.onLayerDragged(centerCoords, this.marker.options.alt);
                        }
                    }
                }
            }.bind(this)
        );
    }

    _moveFollowDraggableLayer(latlng) {
        var diff11 = latlng.lat - this.centerPoint[0];
        var diff12 = latlng.lng - this.centerPoint[1];
        if (this.followDraggableLayerGeometry.length) {
            const followLayerCoords = this.followDraggableLayerGeometry.initialGeom.geometry
                .coordinates[0];
            const newCoords = {
                type: this.followDraggableLayerGeometry.initialGeom.type,
                id: this.followDraggableLayerGeometry.initialGeom.id,
                properties: this.followDraggableLayerGeometry.initialGeom.properties,
                geometry: {
                    type: this.followDraggableLayerGeometry.initialGeom.geometry.type,
                    coordinates: []
                }
            };
            newCoords.geometry.coordinates = [
                [
                    [followLayerCoords[0][0] + diff12, followLayerCoords[0][1] + diff11],
                    [followLayerCoords[1][0] + diff12, followLayerCoords[1][1] + diff11],
                    [followLayerCoords[2][0] + diff12, followLayerCoords[2][1] + diff11],
                    [followLayerCoords[3][0] + diff12, followLayerCoords[3][1] + diff11]
                ]
            ];
            this.followDraggableLayerGeometry.newGeom = newCoords;
            this.followDraggableLayer = L.geoJSON(newCoords).addTo(this._map);
        }
    }

    _getLayerStyle(geojson) {
        let style = '';
        //console.log(geojson);
        if (geojson.type === 'symbol') {
            style = L.icon({
                iconUrl: geojson.properties.iconUrl,
                //iconUrl: via_icon,
                iconSize: [50, 50],
                iconAnchor: [25, 50]
            });
        } else {
            style = {
                color: geojson.paint['fill-color'],
                weight: geojson.paint['line-width'],
                fillOpacity: geojson.paint['fill-opacity']
            };
        }
        return style;
    }

    _drawLayer(geojson) {
        if (this._map.hasLayer(geojson.source.data)) {
            //Layer already exist
        } else {
            const newGroup = L.featureGroup();
            const newLayer = L.geoJSON(geojson.source.data, {
                style: this._getLayerStyle(geojson)
            });
            newLayer.addTo(newGroup);
            newGroup.addTo(this._map);
            // return newLayer;
        }
    }

    _drawWmsLayers(wmsLayers, prevWmsLayers) {
        if (prevWmsLayers) {
            prevWmsLayers.forEach(layer => {
                this._map.removeLayer(layer);
            });
        }
        if (wmsLayers) {
            wmsLayers.forEach(wmsLayer => {
                this._drawWmsLayer(wmsLayer);
            });
        }
    }

    _drawWmsLayer(wmsLayer) {
        if (this.wmsLayer) {
            this.wmsLayer.remove();
        }
        this.wmsLayer = L.tileLayer
            .wms(wmsLayer.wmsUrl + '?api_key=' + wmsLayer.apiKey, {
                layers: wmsLayer.layers,
                minZoom: wmsLayer.minZoom,
                maxZoom: wmsLayer.maxZoom,
                format: 'image/png',
                transparent: true
            })
            .addTo(this._map);
    }

    _changeMapView() {
        if (this.props.fitBounds) {
            return;
        }
        var latlng = L.latLng(this.props.centerCoords.lat, this.props.centerCoords.lng);
        if (!this.props.shouldFly) {
            this._map.panTo(latlng, this.props.zoomLevel);
        } else {
            this._map.flyTo(latlng, this.props.zoomLevel);
        }
    }

    render() {
        window.lmap = this._map;
        return (
            <div className="nkm-mapbox-map leafletMap">
                <div id="map" ref={el => (this._mapContainer = el)} />
            </div>
        );
    }
}

LeafletMap.propTypes = exact({
    rightClickMapPoupContent: PropTypes.string,
    onMapRightClick: PropTypes.func,
    maptype: PropTypes.string,
    accessToken: PropTypes.string,
    className: PropTypes.string,
    centerCoords: PropTypes.object,
    zoomLevel: PropTypes.number,
    shouldFly: PropTypes.bool,
    layers: PropTypes.array,
    marker: PropTypes.object,
    controls: PropTypes.array,
    events: PropTypes.array,
    onMapLoaded: PropTypes.func,
    interactive: PropTypes.bool,
    onLayerDragged: PropTypes.func,
    recenterMap: PropTypes.bool,
    onMoveend: PropTypes.func,
    featureGroup: PropTypes.object,
    featureGroupStyle: PropTypes.object,
    navigationControls: PropTypes.shape({
        show: PropTypes.bool,
        options: PropTypes.obj
    }),
    fitBounds: PropTypes.shape({
        bounds: PropTypes.array,
        options: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    }),
    drawPolygon: PropTypes.shape({
        create: PropTypes.bool,
        succesfullyCreated: PropTypes.func
    }),
    wmsLayers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            minZoom: PropTypes.number,
            maxZoom: PropTypes.number,
            url: PropTypes.string
        })
    )
});

LeafletMap.defaultProps = {
    centerCoords: {lat: 65.2, lng: 15.4},
    zoomLevel: 3.6,
    shouldFly: true,
    layers: null,
    marker: null,
    controls: null,
    events: null,
    onMapLoaded: null,
    interactive: true,
    fitBounds: null,
    onMoveend: null,
    recenterMap: true,
    featureGroup: null,
    navigationControls: null //set to default {show: true, options: null} in code
};

export default LeafletMap;
