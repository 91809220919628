import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import exact from 'prop-types-exact';
import 'nka-icon-button/dist/style.css';
import 'nkm-mapbox-map/dist/style.css';
import LeafletMap from './LeafletMap/LeafletMap';
import SatelliteBtn from './SatelliteBtn';
import NKSpinner from './NKSpinner';
// import './satelliteBtn.css';
import './map.css';
import Via_icon from './icons/Via-icon.svg';
import Til_icon from './icons/Til-icon.svg';
import Fra_icon from './icons/Fra-icon.svg';

class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lat: 65,
            lng: 15,
            zoom: 5,
            points: [],
            route: null,
            zoomPoint: null,
            shouldFitBounds: false,
            shouldRecenterMap: false,
            satImg: true,
            //rightClickMapPoupContent: '<p>test</p>',
            rightClickMapLatLng: null
        };

        this.onLayerDragged = this.onLayerDragged.bind(this);
        this.changeMap = this.changeMap.bind(this);
    }

    zoomToPoint = marker => {
        this.zoomToPointOnMap(
            marker[0].source.data.geometry.coordinates[1],
            marker[0].source.data.geometry.coordinates[0],
            15
        );
    };

    zoomToPointOnMap = (lat, lng, zoom) => {
        this.setState({
            lat: lat,
            lng: lng,
            shouldRecenterMap: true,
            zoom: zoom
        });
    };

    zoomToSeveralPoints(layers) {
        let coordinates = this.getMarkerCoordinates(layers);
        let stateObj = {
            shouldFitBounds: true,
            fitBounds: {
                bounds: coordinates,
                options: {
                    padding: 100,
                    linear: false
                }
            }
        };

        this.setState(stateObj);
    }

    zoomToLayers() {
        const layers = this.props.layers.filter(layer => layer.id !== 'route');
        if (layers.length === 1) {
            this.zoomToPoint(this.props.layers);
        } else if (this.props.layers.find(layer => layer.id === 'route')) {
            const boundingBox = this.props.layers.find(layer => layer.id === 'route').properties.boundingBox;
            const coordinates = [boundingBox.MaxX, boundingBox.MaxY, boundingBox.MinX, boundingBox.MinY];
            let stateObj = {
                shouldFitBounds: true,
                fitBounds: {
                    bounds: coordinates,
                    options: {
                        padding: 100,
                        linear: false
                    }
                }
            };
    
            this.setState(stateObj);
        } else if (layers.length > 1) {
            this.zoomToSeveralPoints(layers);
        } else {
            console.log('Tried to pan to layers but there were no layers');
        }
    }

    // array.map, array.some, array.filter, array.forEach

    getMarkerCoordinates(markers) {
        let markerCoords = markers.map(marker => {
            return marker.source.data.geometry.coordinates;
        });
        return markerCoords;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shouldFitBounds) {
            this.setState({shouldFitBounds: false});
        }
        if (this.state.shouldRecenterMap) {
            this.setState({shouldRecenterMap: false});
        }
        if (prevProps.layers !== this.props.layers && !this.props.isLayerDragged) {
            this.zoomToLayers();
        }

        if (JSON.stringify(prevProps.zoomPoint) !== JSON.stringify(this.props.zoomPoint)) {
            if (this.props.zoomPoint) {
                this.zoomToPointOnMap(this.props.zoomPoint.lat, this.props.zoomPoint.lng, 15);
            }
        }
    }

    defineMarker(lat, lng, searchBoxInfo) {
        return {
            id: searchBoxInfo.name + searchBoxInfo.index,
            type: 'symbol',
            source: {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat]
                    },
                    properties: {
                        title: searchBoxInfo.name + searchBoxInfo.index,
                        icon: searchBoxInfo.name
                    }
                }
            },
            layout: {
                'icon-image': '{icon}-A',
                'icon-size': 0.23,
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true
            },
            properties: {
                draggable: true,
                toolTip: 'test',
                popupContent: '<div class="marker-popup"></div>',
                name: searchBoxInfo.name + searchBoxInfo.index,
                iconUrl:
                    searchBoxInfo.name === 'Til'
                        ? Til_icon
                        : searchBoxInfo.name === 'Fra'
                            ? Fra_icon
                            : Via_icon
            }
        };
    }

    onLayerDragged(newCoords, id) {
        const lat = newCoords[0];
        const lng = newCoords[1];
        const searchBoxInfo = {
            name: id.slice(0, -1),
            index: id.slice(-1)
        };
        const marker = this.defineMarker(lat, lng, searchBoxInfo);
        this.props.onLayerDragged(marker);
    }

    changeMap() {
        this.setState({
            satImg: !this.state.satImg
        });
    }

    onMapRightClick = (latlng, id) => {
        // console.log(latlng);
        let elements = document.getElementsByClassName('leaflet-popup-content-wrapper');
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            ReactDOM.render(this.makeRightClickMapPopupContent(latlng), element);
        }
    }
    AddFromAndTo(latlng, searchBoxInfo) {
        const marker = this.defineMarker(latlng.lat, latlng.lng, searchBoxInfo);
        this.props.onLayerDragged(marker);
    }

    AddSubRoutes(latlng) {
        this.props.setSubRoutes(latlng);
    }
    makeRightClickMapPopupContent(latlng) {
        return (
            <div className = "rightClickPopupContent" >
                <div onClick={() => this.AddFromAndTo(latlng, {name: 'Fra', index: 0})}>
                    <img src={Fra_icon} alt="A"></img>
                </div>
                <div onClick={() => this.AddSubRoutes(latlng)}>
                    <img src={Via_icon } alt="Via"></img>
                </div>
                <div onClick={() => this.AddFromAndTo(latlng, {name: 'Til', index: 1})}>
                    <img src={Til_icon} alt="B"></img>
                </div>
            </div>);
    }

    render() {
        return (
            <div className="laanekassenMapContainer">
                <div className='spinner-wrapper '>
                    <div className={'spinner-sitkart loading-information ' + (this.props.loadRoute ? 'show' : 'hide')}>
                        <div className='content'>
                            <NKSpinner size="2" color='nk-green'/>
                            <p>{this.props.loadingText}</p>
                        </div>
                    </div>
                </div>
                <SatelliteBtn
                    changeMap={this.changeMap}
                    satImg={this.state.satImg}>
                </SatelliteBtn>
                <LeafletMap
                    maptype={this.state.satImg ? 'medium' : 'hybrid'}
                    className="laanekassenMap"
                    centerCoords={{
                        lat: this.state.lat,
                        lng: this.state.lng
                    }}
                    fitBounds={this.state.shouldFitBounds ? this.state.fitBounds : null}
                    onMapRightClick={this.onMapRightClick}
                    rightClickMapPoupContent={this.state.rightClickMapPoupContent}
                    recenterMap={this.state.shouldRecenterMap}
                    zoomLevel={this.state.zoom}
                    marker={this.props.markerCoordinates}
                    layers={this.props.layers ? this.props.layers : undefined}
                    onLayerDragged={this.onLayerDragged}
                    navigationControls={{show: true, options: 'top-left'}}
                    accessToken="pk.eyJ1Ijoia2Vpbm8iLCJhIjoiY2psMzhkd2RpMXN4OTN2b2R4dmphY2kwdSJ9.7sMj4VbfLN1RO4i-NkxNgw"
                />
            </div>
        );
    }
}
Map.propTypes = exact({
    setAllLayers: PropTypes.func.isRequired,
    layers: PropTypes.array,
    markerCoordinates: PropTypes.object,
    zoomPoint: PropTypes.object,
    onLayerDragged: PropTypes.func,
    isLayerDragged: PropTypes.bool,
    loadRoute: PropTypes.bool,
    setSubRoutes: PropTypes.func
});

export default Map;
