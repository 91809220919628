import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import './routeDescription.css';
import RouteDescriptionLabel from './RouteDesctiptionLabel';
import {getTimeString, getDistanceString} from 'util/timeHandler';
import clock_icon from './icons/nk_klokke-A.svg';


class RouteDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routeInfo: [],
            routeLength: 0,
            routeTime: 0
        };
        this.onTableLeave = this.onTableLeave.bind(this);
    }
    componentDidMount() {
        if (this.props.route) {
            this.setUpRouteInfo();
        }
    }
    setUpRouteInfo() {
        let res = this.props.route.RouteInfo.filter(x =>
            x.InfoList.some(x => x.Type === 'TerminalInfo')
        );

        let stateObj = {
            routeInfo: this.props.route.RouteInfo
        };

        res.forEach((RouteInfo, routeIndex) => {
            let tf = RouteInfo.InfoList.find(x => x.Type === 'TerminalInfo');

            if (tf.TerminalIndex === 0) {
                tf.IconHint = 'start-rute';
            } else if (routeIndex === res.length - 1) {
                tf.IconHint = 'slutt-rute';
            } else {
                tf.IconHint = 'punktmarkoer';
            }
            let index = stateObj.routeInfo.findIndex(info => info.Index === RouteInfo.Index);
            let infoIndex = stateObj.routeInfo[index].InfoList.findIndex(
                x => x.Type === 'TerminalInfo'
            );
            stateObj.routeInfo[index].InfoList[infoIndex] = tf;
        });

        stateObj.routeLength = getDistanceString(
            this.getDistanceFromJson(this.props.route.CostList)
        );
        stateObj.routeTime = getTimeString(this.getTimeFromJson(this.props.route.CostList));
        this.setState(stateObj);
    }
    findPreviousRoadInfo(index) {
        let prevIndex = index - 1;
        let element = this.props.route.RouteInfo[prevIndex];
        if (element.InfoList.some(x => x.Type === 'RoadInfo')) {
            return element;
        } else {
            return this.findPreviousRoadInfo(prevIndex);
        }
    }
    componentDidUpdate(prevProps, prevStates) {
        if (JSON.stringify(prevProps.route) !== JSON.stringify(this.props.route)) {
            this.setUpRouteInfo();
        }
    }

    getTimeFromJson(costList) {
        return costList
            ? costList.find(x => x.Name.toLowerCase().includes('time'))
                ? costList.find(x => x.Name.toLowerCase().includes('time')).Cost
                : 0
            : 0;
    }
    getDistanceFromJson(costList) {
        return costList
            ? costList.find(x => x.Name.toLowerCase().includes('distance'))
                ? costList.find(x => x.Name.toLowerCase().includes('distance')).Cost
                : 0
            : 0;
    }
    onTableLeave() {
        this.props.onLabelHover(null);
    }
    //comment
    //style={{display: this.props.show ? 'block' : 'none'}}>
    render() {
        let className = this.props.show ? 'show' : 'hide';
        return (
            <div className={'routeDescriptionBox ' + className}>
                <header>
                    <div className={'routeDescriptionBoxToolBar'}>
                        <h1>Rutebeskrivelse</h1>
                    </div>
                    {/*<h4>
                        Rutens lengde: {this.state.routeLength} / {this.state.routeTime}
                    </h4>*/}
                </header>

                <div className={'Table'} onMouseLeave={this.onTableLeave}>
                    {this.state.routeInfo.map((routeInfo, index) => (
                        <RouteDescriptionLabel
                            key={index}
                            routeInfo={routeInfo}
                            index={index}
                            onLabelHover={this.props.onLabelHover}
                            onLabelClick={this.props.onLabelClick}
                        />
                    ))}
                    <div className='driving-time'>
                        <img src={clock_icon} alt="clock-icon" />
                        <br/>
                        <b>Kjøretid:</b>
                        <p>
                            {getTimeString(this.props.route.CostList[1].Cost)}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
RouteDescription.propTypes = exact({
    route: PropTypes.object,
    show: PropTypes.bool,
    onLabelHover: PropTypes.func,
    onLabelClick: PropTypes.func
});

export default RouteDescription;
