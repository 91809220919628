import 'core-js/fn/promise';
import 'core-js/es6/map';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/some';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
//import 'core-js/fn/object/assign';


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import registerServiceWorker from './registerServiceWorker';


ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
