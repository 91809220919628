import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import LabelSearch from './LabelSearch';
import getSetting from 'getSetting';
class LabelSearchComponent extends Component {
    render() {
        const apiKey = getSetting('REACT_APP_SEARCH_API_KEY');

        return (
            <LabelSearch
                {...this.props}
                apiKey={apiKey}
                setRef={this.props.setRef}
                marker = {this.props.marker}
                markerToolTip={this.props.markerToolTip}
                zoomOnClick={this.props.zoomOnClick}
                whenClearingResults={this.props.whenClearingResults}
            />
        );
    }
}

LabelSearchComponent.propTypes = exact({
    labelName: PropTypes.string.isRequired,
    targets: PropTypes.arrayOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string,
    hitSelected: PropTypes.func.isRequired,
    children: PropTypes.object,
    index: PropTypes.number,
    whenClearingResults: PropTypes.func,
    getCoordinateResult: PropTypes.func,
    setRef: PropTypes.func,
    marker: PropTypes.object,
    markerToolTip: PropTypes.object,
    zoomOnClick: PropTypes.func,
    setSnackBarContent: PropTypes.func
});

export default LabelSearchComponent;
