import React, {Component} from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import './tabMenu.css';

class TabMenu extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.activeTab
        };
    }

    _addHeader = (tabHeader, index) => {
        return (
            <button
                key = {index}
                className = {index === this.state.activeTab ? 'active' : ''}
                onClick={() => this._openContent(index)}
            >
                {tabHeader}
            </button>
        );
    }

    _openContent = tabIndex => {
        this.setState({
            activeTab: tabIndex
        });
    }

    _getBorderPosition = () => {
        const style = {
            left: this.state.activeTab * this.props.buttonWidth};
        return style;
    }

    render() {
        return (
            <React.Fragment>
                <div className = 'tab-menu'>
                    <div className = 'tab-header' style={{width: this.props.content.length * this.props.buttonWidth}}>
                        {this.props.content.map(
                            (tab, index) => this._addHeader(tab.header, index)
                        )}
                        <div className = 'tab-header-border' style = {this._getBorderPosition()}/>
                    </div>
                    <div className = 'tab-content'>
                        {this.props.content[this.state.activeTab].content}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

TabMenu.defaultProps = {
    activeTab: 0,
    buttonWidth: 170,
    content: [
        {
            header: 'Header 0',
            content: <div>{'Content 0'}</div>
        },
        {
            header: 'Header 1',
            content: <div><h1>{'Content 1'}</h1></div>
        },
        {
            header: 'Header 3',
            content: <div><h1>{'Content 3'}</h1></div>
        }
    ]
};


TabMenu.propTypes = exact({
    content: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string,
        content: PropTypes.object
    })),
    activeTab: PropTypes.number,
    buttonWidth: PropTypes.number
});

export default TabMenu;