import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import NkmSearch from 'nkm-norkart-search';
import {subSearch} from 'nkm-norkart-search/dist/subSearchFunctions';
import Icon from 'nka-icons';
import {coordinateSearch} from 'nkm-norkart-search/dist/extraSearchFunctions';
import proj4 from 'proj4';
import NorkartTooltip from 'components/Tooltip/NorkartToolltip.js';

const LabelSearch = props => {
    const getResultFormatted = res => {
        props.hitSelected(res, {name: props.labelName, index: props.index});
    };

    const getCoordinateResult = coordinates => {
        let res = coordinateSearch(coordinates);
        if (res) {
            props.getCoordinateResult(res, {name: props.labelName, index: props.index});
            return res;
        }
    };
    const getSavedResult = () => {
        return JSON.parse(sessionStorage.getItem(props.labelName + props.index));
    };

    const getMarkerString = () => {
        if (props.marker) {
            if (props.marker.id === props.labelName + props.index) {
                let utmNumber = '+proj=utm +zone=33';
                let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
                let numberArray = [
                    Number.parseFloat(props.marker.source.data.geometry.coordinates[0]),
                    Number.parseFloat(props.marker.source.data.geometry.coordinates[1])
                ];
                let latlon = proj4(utmNumber, wgs84).inverse(numberArray);

                let coords = latlon;

                let res =
                coords[0].toFixed(3) + 'E' +
                    ' ' +
                    coords[1].toFixed(3) + 'N';

                return res;
            }
        }
    };
    const getTitleString = () => {
        if (props.markerToolTip) {
            if (props.markerToolTip.id === props.labelName + props.index) {
                let latlng =
                    props.markerToolTip.coordinates.lat.toString() +
                    ' ' +
                    props.markerToolTip.coordinates.lng.toString();
                return latlng;
            }
        }
        return '';
    };
    const copyToClipboard = e => {
        if (navigator && navigator.clipboard) {
            navigator.permissions.query({name: 'clipboard-write'}).then(result => {
                if (result.state === 'granted' || result.state === 'prompt') {
                    /* write to the clipboard now */

                    updateClipboard(
                        `${props.markerToolTip.coordinates.lat} ${
                            props.markerToolTip.coordinates.lng
                        }`
                    );
                }
            });
        } else {
            if (document.queryCommandSupported('copy')) {
                let text = `${props.markerToolTip.coordinates.lat} ${
                    props.markerToolTip.coordinates.lng
                }`;
                fallbackCopyTextToClipboard(text);
            } else {
                props.setSnackBarContent('Copy not supported', false);
            }
        }
    };
    const updateClipboard = newClip => {
        navigator.clipboard.writeText(newClip).then(
            function () {
                /* clipboard successfully set */
                props.setSnackBarContent('Copied: ' + newClip);
            },
            function () {
                /* clipboard write failed */
                props.setSnackBarContent('Copy failed: ', false);
            }
        );
    };

    const fallbackCopyTextToClipboard = text => {
        let x = document.createElement('INPUT');
        x.setAttribute('type', 'text');
        x.setAttribute('value', text);
        document.body.appendChild(x);
        x.focus();
        x.select();

        try {
            let msg = document.execCommand('copy');
            if (msg) {
                props.setSnackBarContent('Copied: ' + text);
            } else {
                props.setSnackBarContent('Copy failed: ', false);
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            props.setSnackBarContent('Fallback: Copy not supported', false);
        }

        document.body.removeChild(x);
    };
    const onClick = e => {
        if (props.markerToolTip) {
            if (e.nativeEvent && e.nativeEvent.type === 'click' && e.ctrlKey) {
                copyToClipboard(e);
            } else {
                props.zoomOnClick(props.markerToolTip.coordinates);
            }
        }

        return null;
    };
    return (
        <React.Fragment>
            <div className="rowContainer">
                <NorkartTooltip title={getTitleString()} placement="top">
                    <label
                        className={props.markerToolTip ? 'column1 clickable' : 'column1'}
                        onClick={e => onClick(e)}>
                        <Icon
                            icon={
                                props.labelName === 'Til'
                                    ? 'slutt-rute'
                                    : props.labelName === 'Fra'
                                        ? 'start-rute'
                                        : 'punktmarkoer'
                            }
                            size="1"
                        />
                    </label>
                </NorkartTooltip>
                <NkmSearch
                    classNames="column2"
                    ref={props.setRef}
                    targets={props.targets}
                    apiKey={props.apiKey}
                    placeholder={props.placeholder}
                    closeOnSelect={true}
                    hitSelected={getResultFormatted}
                    numResults={10}
                    subSearch={subSearch}
                    savedHit={getSavedResult()}
                    extraSearch={getCoordinateResult}
                    setCoordinates={getMarkerString()}
                    whenClearingResults={props.whenClearingResults.bind(
                        this,
                        props.labelName,
                        props.index
                    )}
                />
                <div className="column3">{props.children}</div>
            </div>
        </React.Fragment>
    );
};

LabelSearch.propTypes = exact({
    apiKey: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    targets: PropTypes.arrayOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string,
    hitSelected: PropTypes.func.isRequired,
    getCoordinateResult: PropTypes.func,
    children: PropTypes.object,
    index: PropTypes.number,
    whenClearingResults: PropTypes.func,
    setRef: PropTypes.func,
    marker: PropTypes.object,
    markerToolTip: PropTypes.object,
    zoomOnClick: PropTypes.func,
    setSnackBarContent: PropTypes.func
});

export default LabelSearch;
