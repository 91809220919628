import getSetting from 'getSetting';
import NorkartIdJs from 'norkartidjs2';

const config = {
    clientId: getSetting('REACT_APP_CLIENT_ID'),
    postLogoutUrl: window.location,
    useApplicationBackend: true,
    profile: getSetting('REACT_APP_WAAPI_PROFILE')
};

const norkartIdJs = new NorkartIdJs(config);
export default norkartIdJs;
